(function($) {

	toggleNavigation = function() {
		$('.navigation--trigger').on('click', function(e){
			e.preventDefault();
			if($('body').hasClass('navigation--open')) {
				$('body').removeClass('navigation--open');
			}
			else {
				$('body').addClass('navigation--open');
			}
		}); 
	};

	stickyNavigation = function() {
		var scrollTop = 0;
		if ($(window).width() >= 768) {
			scrollTop = 117;
		}
		if ($(window).width() >= 1024) {
			scrollTop = 109;
		}

		if ($(this).scrollTop() >= scrollTop ){  
			$('body').addClass('header--sticky');
		}
		else {
			$('body').removeClass('header--sticky');
		}
	};

})(jQuery); // Fully reference jQuery after this point.
